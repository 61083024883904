import React from 'react'
import '../components/Styles/gridlex.scss'
import '../components/Styles/global.css' 

import NovaLayout from '../components/NovaLayout'
import { PageHeader } from '../components/PageHeader'
import { ColumnTextSection } from '../components/ColumnTextSection'
import { StepsHorizontalSection } from '../components/StepsHorizontalSection'
import { ImageTextSection } from '../components/ImageTextSection'
import { CTASection } from '../components/CTASection'

import Agile from "../images/Agile.png"

import ProcessSteps from "../utils/processSteps.js"


const Index = () => {
  return (
    <NovaLayout title="Process | Nova Digital Agency"  description="A digital agency designed for growth-driven mortgage businesses">
      <PageHeader prompt="The Nova Process" heading="Our Methodology" />
      <StepsHorizontalSection/>
      {ProcessSteps.map(step => <ColumnTextSection subHeader={step.subHeader} key={step.subHeader} firstColumn={step.firstColumn} secondColumn={step.secondColumn} tag={step.tag} purple={step.purple}/>)}
      <div className="layout-body">
        <div className="pb-3 xs-pb-1">
          <div className="grid-12-center">
            <div className="hr mb-4 xs-mb-4 mt-4 xs-mt-0-5" />
            <ImageTextSection image={Agile} header="Results-Driven Delivery Model"  text="As your virtual marketing team, we’ll work hand in hand with you on strategy, on an agile weekly meeting.  We run this meeting in the Traction Level 10 format and we’ll be happy to train your team around the methodology that gets results, while holding key stakeholders accountable.   This way we stay focused and efficient.  We’ll organize our efforts on a Trello board to launch, measure and learn on our efforts and hold each other accountable to deliver on ongoing tasks and projects."/>
          </div>
        </div>
      </div>
      <CTASection 
        heading="How can we help?"
        prompt="Let's Get Started"
        buttonText="Get Started"
        gtaText="gtm-process-cta-section-get-started"
      />
    </NovaLayout>
  )
}

export default Index
