import React from 'react'
import './styles.css'
import StepsArray from '../../utils/stepsArray.js'


export const StepsHorizontalSection = (props) => {
  return (    
    <div className="layout-body">
      <div className="grid mb-6 mt-6 xs-mb-2">
        {StepsArray.map(step => <div key={step.name} className="col-4_xs-12 xs-center text-center">
          <div className="text-small mb-0-5 xs-mb-0-5">
            <img className="steps-horizontal-section-image" src={step.image}/>
          </div>
          <div className="text-medium xs-mb-2">
            <h4 className="mb-0-5">{step.title}</h4>
            {step.text}
          </div>
        </div>)}
      </div>
    </div>
  )
}

