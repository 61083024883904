import React from 'react'

const ProcessSteps= [
    {
        tag: "Step 1",
        purple: false,
        subHeader: "Get started with a free assessment.",
        firstColumn: <div>
            <p>In our assessment, we’ll dive in and uncover challenges, opportunities and goals across the following areas:</p>
            <p><strong>Process, Data & Technology</strong></p>
            <p>We’ll jump on a screenshare to get a feel for your existing technologies and processes.</p> 
            <p>We’ll go from start to finish of the customer life cycle, from inbound marketing through to close and renewal.</p> 
            <p>We’ll dive into the tech to identify untapped opportunities and limitations, including your website, marketing stack, CRM, and mortgage origination system.</p>
        </div>,
        secondColumn: <div>
            <p><strong>People</strong></p>
            <p>We’ll seek to further understand who the key stakeholders are in your team and how they contribute to your strategic goals.</p>
            <p>We’ll meet with these stakeholders and get a deeper sense for what’s working or can be improved.</p>
            <p><strong>Messaging</strong></p>
            <p>We’ll explore your brand values, brand voice and visual identity.  We discuss where you are and where we’d like to be.</p>
        </div>
    },
    {
        tag: "Step 2",
        purple: true,
        subHeader: "Build the foundation and align",
        firstColumn: <div>
            <p>Next we’ll capture our findings in a deliverable that both summarizes our assessment and maps a suggested path to capitalize on opportunities identified.</p>
            <p><strong>Alignment</strong></p>
            <p>We’ll take advantage of opportunities and implement industry leading technologies and processes that work to align marketing and sales.</p> 
            <p>This could be a beautiful, content rich website that converts more leads and / or a marketing and sales ops system to segment and nurture leads to close with personalized email campaigns.</p>
        </div>,
        secondColumn: <div>
            <p>Alignment is key to long term success, so we’ll train and rally your team around these improvements, so sales and marketing are in synergy working towards the same goal.</p>
            <p><strong>Data Reporting</strong></p>
            <p>Before the acceleration phase begins, we map your goals to actionable metrics and key performance indicators to make sure that we are driving in the right direction.</p>
            <p>We’ll ensure the system reports these KPI’s so the entire team can make data driven decision on an ongoing basis.</p>
        </div>
    },
    {
        tag: "Step 3",
        purple: false,
        subHeader: "Turn up inbound and accelerate.",
        firstColumn: <div>
            <p>We’ll then hit the ground running with marketing and put our full focus on results.</p>
            <p><strong>Launch Campaigns</strong></p>
            <p>We’ll launch our strategic messaging campaigns to our targeted segment lists, think first time buyers, divorcees, realtors, wherever they are in the life cycle.</p>
            <p>These lists will keep growing as our new tech and process turns more visitors into subscribers.  Each campaign will be tested against the market and optimized based on returned data.</p>
        </div>,
        secondColumn: <div>
            <p><strong>Ongoing Support</strong></p>
            <p>We’ll serve as your ongoing marketing specialist, working in sync with the team.  You can choose a monthly subscription package that suits your growth ambitions.</p>
            <p>Services include Search Engine Marketing, SEO, Content marketing, Realtor engagement, Cross Platform Social Media Management and Ad Retargetting.</p>
            <p>We’ll run A/B tests and will continuously assess and adjust the sales and marketing approaches to optimize efficiency and results.</p>
        </div>
    }
]

export default ProcessSteps

