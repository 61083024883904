import FlowerTing from '../images/FlowerTing.svg'
import PlanningScreen from '../images/PlanningScreen.svg'
import Rocket from '../images/Rocket.svg'

const StepsArray= [
    {
        image: PlanningScreen,
        tag: "Step 1",
        title: "Assess", 
        text: "We deep dive into your brand, process and technology, identifying challenges and opportunites.",
        link: "/process",
        button: "Learn more"
    },
    {
        image: FlowerTing,
        tag: "Step 2",
        title: "Align", 
        text: "We build the foundation for success and harmonize sales, marketing and technology.",
        link: "/process",
        button: "Learn more"
    },
    {
        image: Rocket,
        tag: "Step 3",
        title: "Accelerate", 
        text: "We serve as your ongoing marketing specialists and supercharge your growth. ",
        link: "/process",
        button: "Learn more"
    }
    

]

export default StepsArray 

